import {
  IconBathroom,
  IconBedroom,
  IconElevator,
  IconExterior,
  IconParking,
  IconStorage,
  IconSwimmingPool,
  IconTerrace
} from '@uda/bento-components';
import { correctedPriceValueGetter, weightTotalValueGetter } from './calculator';

export const getCellAlign = field => {
  switch (field) {
    case 'source':
    case 'usage':
    case 'cadastre':
    case 'address':
    case 'build_status':
      return 'left';
    case 'asset_value':
    case 'sqm_value':
    case 'construction_year':
    case 'date_out':
    case 'area':
    case 'floor':
    case 'id_uda':
    case 'lat':
    case 'lon':
    case 'nbaths':
    case 'rooms':
    case 'time_published':
    case 'distance':
      return 'right';
    case 'operation':
    case 'has_elevator':
    case 'has_garage':
    case 'has_pool':
    case 'is_exterior':
    case 'has_storage':
    case 'has_terrace':
    case 'new_dev':
    case 'selected':
    default:
      return 'center';
  }
};

export const getCellWidth = field => {
  switch (field) {
    case 'selected':
      return { width: 35, minWidth: 35 };
    case 'floor':
    case 'rooms':
    case 'nbaths':
    case 'has_elevator':
    case 'has_terrace':
    case 'has_garage':
    case 'has_storage':
    case 'has_pool':
    case 'is_exterior':
    case 'new_dev':
    case 'id_uda':
    case 'lat':
    case 'lon':
    case 'asset_value':
    case 'sqm_value':
    case 'construction_year':
    case 'other':
    case 'annexes':
    case 'location_quality':
    case 'negociation':
    case 'operation':
    case 'source':
    case 'usage':
    case 'distance':
    case 'weight':
    case 'date_out':
    case 'area':
      return { width: 120, minWidth: 120 };
    case 'build_status':
    case 'time_published':
      return { width: 130, minWidth: 130 };
    case 'corrected':
      return { width: 160, minWidth: 160 };
    case 'cadastre':
    case 'address':
      return { width: 240, minWidth: 240 };
    default:
      return { width: 100 };
  }
};

const getCellIcon = (field, sort) => {
  const iconColor = sort ? '#1778FB' : '#747D93';
  switch (field) {
    case 'rooms':
      return <IconBedroom customColor={iconColor} />;
    case 'nbaths':
      return <IconBathroom customColor={iconColor} />;
    case 'has_elevator':
      return <IconElevator customColor={iconColor} />;
    case 'has_terrace':
      return <IconTerrace customColor={iconColor} />;
    case 'has_garage':
      return <IconParking customColor={iconColor} />;
    case 'has_storage':
      return <IconStorage customColor={iconColor} />;
    case 'has_pool':
      return <IconSwimmingPool customColor={iconColor} />;
    case 'is_exterior':
      return <IconExterior customColor={iconColor} />;
    default:
      return null;
  }
};

const getCellRenderer = field => {
  switch (field) {
    case 'selected':
      return 'checkboxCell';
    case 'operation':
      return 'operationCell';
    case 'weight':
      return 'editCell';
    case 'has_elevator':
    case 'has_terrace':
    case 'has_garage':
    case 'has_storage':
    case 'has_pool':
    case 'is_exterior':
    case 'area':
    case 'construction_year':
    case 'rooms':
    case 'nbaths':
    case 'floor':
    case 'negociation':
    case 'location_quality':
    case 'annexes':
    case 'other':
      return 'compoundEditCell';
    case 'corrected':
      return 'correctedPriceCell';
    case 'new_dev':
      return 'booleanCell';
    case 'source':
    case 'usage':
    case 'asset_value':
    case 'sqm_value':
    case 'date_out':
    case 'time_published':
    case 'cadastre':
    case 'id_uda':
    case 'lat':
    case 'lon':
    case 'address':
    case 'distance':
    default:
      return 'singleCell';
  }
};

const getCellValueGetter = field => {
  switch (field) {
    case 'corrected':
      return correctedPriceValueGetter;
    case 'weight':
      return weightTotalValueGetter;
    default:
      return null;
  }
};

const getCellVariant = field => {
  switch (field) {
    case 'usage':
    case 'source':
      return 'secondary';
    case 'asset_value':
    case 'sqm_value':
    case 'selected':
    case 'operation':
    case 'area':
    case 'construction_year':
    case 'rooms':
    case 'nbaths':
    case 'has_elevator':
    case 'has_terrace':
    case 'has_garage':
    case 'has_storage':
    case 'has_pool':
    case 'is_exterior':
    case 'floor':
    case 'date_out':
    case 'time_published':
    case 'new_dev':
    case 'cadastre':
    case 'id_uda':
    case 'lat':
    case 'lon':
    case 'address':
    case 'distance':
    default:
      return 'primary';
  }
};

const getCellSortType = field => {
  switch (field) {
    case 'selected':
      return null;
    case 'date_out':
      return 'date';
    case 'asset_value':
    case 'sqm_value':
    case 'area':
    case 'construction_year':
    case 'rooms':
    case 'nbaths':
    case 'floor':
    case 'time_published':
    case 'id_uda':
    case 'lat':
    case 'lon':
    case 'distance':
      return 'number';
    case 'operation':
    case 'source':
    case 'usage':
    case 'has_elevator':
    case 'has_terrace':
    case 'has_garage':
    case 'has_storage':
    case 'has_pool':
    case 'is_exterior':
    case 'new_dev':
    case 'cadastre':
    case 'address':
    default:
      return 'string';
  }
};

const setCellHightlight = field => {
  switch (field) {
    case 'weight':
    case 'corrected':
      return true;
    default:
      return false;
  }
};

const getPinned = field => {
  switch (field) {
    case 'selected':
      return 'left';
    case 'weight':
    case 'corrected':
      return 'right';
    default:
      return false;
  }
};

export const apiInternTable = data => {
  return {
    columns: data.columns.map(e => ({
      ...e,
      ...getCellWidth(e.field),
      icon: getCellIcon(e.field, e.sortDirection),
      align: getCellAlign(e.field),
      cellRenderer: getCellRenderer(e.field),
      variant: getCellVariant(e.field),
      highlight: setCellHightlight(e.field),
      sortType: getCellSortType(e.field),
      pinned: getPinned(e.field),
      lockPinned: !!getPinned(e.field),
      longCell: e.field === 'address',
      valueGetter: getCellValueGetter(e.field),
      showSum: e.field === 'weight',
      pinnedRowCellRenderer: 'weightTotalRowRenderer',
      rounded: false
    })),
    comparables_rent: data.comparables_rent,
    comparables_sale: data.comparables_sale
  };
};

export const getNewTableValues = (assets, changes) => {
  assets[changes.index][changes.field] = changes.value;

  return assets;
};

export const getWeightTableValues = (assets, changes) => {
  if (assets?.length === changes?.length) {
    assets?.forEach((asset, index) => {
      asset.weight.edit = changes[index].value?.edit;
    });
  }

  return assets;
};
