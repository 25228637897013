export const correctedPriceValueGetter = ({ data }) => {
  if (data.asset_value) {
    const percent_sum = Object.values(data)
      .filter(e => e.edit && e.primary)
      .reduce((prev, current) => prev + current.edit.value, 0);

    const primary = data.asset_value.primary.value;
    const secondary = data.sqm_value.primary.value;

    const total_applied = percent_sum !== 0 ? primary + (primary * percent_sum) / 100 : primary;
    const avg_applied = percent_sum !== 0 ? secondary + (secondary * percent_sum) / 100 : secondary;

    return {
      percent: {
        primary: { value: percent_sum, format: 'percent' }
      },
      price: {
        primary: { ...data.asset_value.primary, value: total_applied },
        secondary: [{ ...data.sqm_value.primary, value: avg_applied }]
      }
    };
  }
  return false;
};

export const weightTotalValueGetter = ({ data, colDef, node, api }) => {
  const { field } = colDef;
  if (node.rowPinned) {
    const rows = api.getModel().gridOptionsWrapper.gridOptions.rowData;

    const total = rows.reduce((prev, current) => prev + Number(current[field].edit.value), 0);

    return {
      value: total,
      format: 'percent'
    };
  }
  return {
    edit: {
      value: data[field]?.edit?.value,
      format: data[field]?.edit?.format,
      type: data[field]?.edit?.type
    }
  };
};

export const calculateCorrectedValues = ({
  card,
  table: { isTableLoading, comparables_rent, comparables_sale }
}) => {
  let values = {
    sale: null,
    rent: null
  };

  if (!isTableLoading) {
    const area = card.properties.find(e => e.id === 'area').data.value;

    const sale_values = getAvgSqmCorrectedValues(area, comparables_sale);
    let rent_values = getAvgSqmCorrectedValues(area, comparables_rent);

    if (rent_values) {
      rent_values.avm.secondary = {
        value: sale_values?.avm.primary.value
          ? ((rent_values.avm.primary.value * 12) / sale_values.avm.primary.value) * 100
          : null,
        format: 'percent',
        decimals: 1
      };

      rent_values.sqm.secondary = {
        value: sale_values?.sqm.primary.value
          ? ((rent_values.sqm.primary.value * 12) / sale_values.sqm.primary.value) * 100
          : null,
        format: 'percent',
        decimals: 1
      };
    }

    values.sale = sale_values;
    values.rent = rent_values;
  }

  return values;
};

export const getAvgSqmCorrectedValues = (area, comparables) => {
  const comparables_weight = comparables.reduce(
    (prev, current) => prev + current.weight.edit.value,
    0
  );

  if (comparables_weight === 100) {
    const weight_sum = comparables.reduce(
      (prev, data) => {
        const corrected_sum = correctedPriceValueGetter({ data });
        const weight = data.weight.edit.value / 100;
        return {
          avm: prev.avm + corrected_sum.price.primary.value * weight,
          sqm: prev.sqm + corrected_sum.price.secondary[0].value * weight
        };
      },
      { avm: 0, sqm: 0 }
    );

    return {
      avm: { primary: { value: weight_sum.avm, format: 'price', currency: 'EUR' } },
      sqm: {
        primary: {
          value: weight_sum.sqm * area,
          format: 'price',
          currency: 'EUR',
          decimals: 1,
          area: true
        }
      }
    };
  }
  return null;
};

export const formatCorrectedValues = anchor => {
  const { selectedValues } = anchor;
  const values = calculateCorrectedValues(anchor);
  const gross_yield = () => {
    if (selectedValues.rent.selected === 'avm' && values.rent.avm.secondary.value) {
      return Number(values.rent.avm.secondary.value.toFixed(1));
    } else if (selectedValues.rent.selected === 'sqm' && values.rent.sqm.secondary.value) {
      return Number(values.rent.sqm.secondary.value.toFixed(1));
    }
  };

  return {
    sale: values.sale
      ? {
          ...selectedValues.sale,
          avm: Math.round(values.sale.avm.primary.value),
          sqm: Math.round(values.sale.sqm.primary.value)
        }
      : null,
    rent: values.rent
      ? {
          ...selectedValues.rent,
          avm: Math.round(values.rent.avm.primary.value),
          sqm: Math.round(values.rent.sqm.primary.value),
          gross_yield: gross_yield()
        }
      : null
  };
};

export const getComparablesEditValues = ({ table }) => {
  const allComparables = [...table.comparables_rent, ...table.comparables_sale];

  return allComparables.map(comparable => ({
    id: comparable.id.primary.value,
    ...Object.fromEntries(
      Object.entries(comparable)
        .filter(([key, value]) => value.edit)
        .map(([key, value]) => [key, value.edit.value])
    )
  }));
};
