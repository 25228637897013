import { useEffect, useState } from 'react';
import GlobalStyles from '@uda/bento-components/lib/themes/global';
import Routes from './routes';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import { flattenMessages, locale, translations } from './translations';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from './context/index';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { initApplication } from './actions/app';
import { initApp } from './Auth/actions/auth';
import { userSetCookiesConfig } from './utils/cookies';
import CookiesModal from './components/CookiesModal';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-datepicker/dist/react-datepicker.css';

export const history = createBrowserHistory();

const App = () => {
  const { theme } = useAppContext();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(state => state.auth);
  const language = useSelector(state => state.user.data.language) || locale;

  const receiveMessage = evt => {
    const token = evt.data.authorization;
    if (token) dispatch(initApp({ token }));
  };

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(initApplication());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // useEffect(() => {
  //   if (stripeKey !== null && stripe === null) {
  //     setStripe(loadStripe(stripeKey));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [stripeKey]);

  const { isUserFromUda } = useSelector(state => state.user);

  // useTrackingGTag({ isUserFromUda });
  // useInitGTag({ isUserFromUda });

  const routes =
    isAuthenticated !== null ? (
      <Router history={history}>
        <Routes isAuthenticated={isAuthenticated} />
      </Router>
    ) : null;

  const needCookiesConfiguration = !userSetCookiesConfig();

  return (
    <IntlProvider
      locale={language}
      key={language}
      messages={flattenMessages(translations[language])}
    >
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {needCookiesConfiguration ? <CookiesModal /> : null}
        {routes}
      </ThemeProvider>
    </IntlProvider>
  );
};

export default App;
