import FileSaver from 'file-saver';
import { default as turfCentroid } from '@turf/centroid';
import { getBboxFromPolygon } from '../../utils/map';
import { initialState } from '../reducers/smartValuation';
import { averageCalc } from '../utils/math';
import { applyPriceModificationToSelected } from '../reducers/adapter';
import {
  isEmptyArray,
  setNewUrlParams,
  getFiltersFromUrl,
  getCoordinatesFromUrl,
  getCadastralReferenceFromUrl
} from '../../utils';

import { parseComparablesFilters } from '../features/Filters/adapter';
import { FILTERS_CONFIG } from '../features/Filters/filters.config';
import {
  getComparablesData,
  getAssetValuationData,
  getBoundariesFromPolygon,
  getAssetValuationFeatures
} from '../utils/index';
import {
  TASK_STATUS_SUCCESS,
  TASK_STATUS_FAILURE,
  TASK_STATUS_REVOKED,
  N_COMPARABLE_TO_AVERAGE,
  OPERATION
} from '../constants.js';
import { closeNotification, openNotification } from '../../actions/notification';
import { getAverageValueOfComparable } from '../utils/getAverageValueOfComparable';
import { formatSortParams } from '../../utils/sort';
import { fetchSmartValuationRestrictions } from '../../actions/user';
import { transformComparableToSelectedComparable } from '../reducers/adapter';
import { removeEmptyAndNullAndUndefinedValuesFromObject } from '../../utils';
import { setSearch } from '../features/Search/context';
import { getDefaultValuesFilterByUse } from 'SmartValuation/utils/getDefaultValuesFilterByUse';
import { setLoadingFromOperation } from 'Capture/actions/capture';

export const resetInitialState = () => dispatch => {
  dispatch({
    type: 'RESET_STATE',
    payload: null
  });
};

export const onClickComparableSelection = id => (dispatch, getState) => {
  const { operation, selectedComparables, ...state } = getState().smartValuation;
  const result = state.comparables.items.find(c => c.id_uda === id);

  if (result) {
    const comparable = { ...transformComparableToSelectedComparable(result), operation };
    const updateComparables = selectedComparables.some(({ id_uda }) => id_uda === id)
      ? selectedComparables.filter(comparable => comparable.id_uda !== id)
      : [...selectedComparables, comparable];

    dispatch(updateComparableSelection(updateComparables));
  } else {
    const updateComparables = selectedComparables.filter(comparable => comparable.id_uda !== id);
    dispatch(updateComparableSelection(updateComparables));
  }
};

export const getReportsList =
  ({ listing_type }) =>
  async dispatch => {
    const response = await dispatch({
      type: 'GET_REPORTS_LIST',
      payload: {
        request: {
          method: 'GET',
          url: `/b4p/smart-valuation/reports/types/`,
          params: { listing_id: listing_type }
        }
      }
    });

    if (response.type.includes('FAIL')) {
      dispatch(
        openNotification('info', {
          message: 'smartvaluation.report_modal_typology.report_list_error'
        })
      );
    }
  };

export const initSmartValuation = () => async (dispatch, getState) => {
  const { plans, language } = getState().user.data;
  if (plans !== null) dispatch(fetchSmartValuationRestrictions());
  dispatch(changeFilterForm(getFiltersFromUrl()));
  dispatch(fetchFilters(FILTERS_CONFIG[language]));
  dispatch(getData({ ...getCoordinatesFromUrl(), ...getCadastralReferenceFromUrl() }));
};

export const changeFilterFormAdapter = valuation => {
  if (valuation) {
    const { address, address_full, id_uda, ...rest } = valuation;
    return rest;
  }
};
const getCadastralInfo = ({ features = [], lat, lon }) => {
  return features.reduce(
    (obj, feat) => {
      const value = Number(feat?.data?.value);
      if (typeof value !== 'number') return { ...obj };
      return { ...obj, [feat.id]: value };
    },
    { lat, lon }
  );
};

export const getData =
  ({ lat, lon, address, reference } = {}) =>
  async (dispatch, getState) => {
    const { advanceForm, operation, map, search } = getState().smartValuation;
    const { defaultLocation, data } = getState().user;
    const { country_codes } = data;

    let url = window.location.href;
    let formattedUrl = new URL(url);
    let params = new URLSearchParams(formattedUrl.search);

    let latAux = params.get('lat');
    let lonAux = params.get('lon');

    let addressByBypass = null;

    const addressAux = await dispatch(
      fetchGeoReverseAddress(latAux?.toString(), lonAux?.toString(), country_codes)
    );
    if (addressAux?.payload?.data?.complete_address) {
      addressByBypass = `${addressAux?.payload?.data?.complete_address}`;
    }
    if (search.address === '' || search.address === null) {
      search.address = addressByBypass ?? defaultLocation.address;
    }

    if (!reference === undefined) {
      reference = search.selectedActive?.cadastral_ref;
    }

    dispatch(setSearch({ address: address || search.address, url_cadastralReference: reference }));

    let isTheSameAddress = search.address === address;
    const coordinates = {
      lat: lat || (latAux ?? defaultLocation.lat),
      lon: lon || (lonAux ?? defaultLocation.lon)
    };

    const assetCardData = getAssetValuationData(
      {
        reference,
        address: address && address !== '' ? address : addressByBypass ?? '',
        lat: coordinates.lat,
        lon: coordinates.lon,
        ...advanceForm
      },
      isTheSameAddress
    );
    const card = await dispatch(fetchAssetCard(isTheSameAddress, assetCardData));
    if (card.type.includes('SUCCESS')) {
      const cadastralInfo = {
        asset_features: removeEmptyAndNullAndUndefinedValuesFromObject({
          ...getCadastralInfo(card?.payload?.data),
          ...assetCardData.asset_features
        })
      };
      let cadastralRefProperties = undefined;
      if (reference) {
        cadastralRefProperties = {
          asset_features: {
            property_type: search.selectedActive.property_type,
            floor: search.selectedActive.floor,
            has_pool: search.selectedActive.has_pool,
            has_garage: search.selectedActive.has_garage,
            has_storage: search.selectedActive.has_storage
          }
        };
      }
      const data = removeEmptyAndNullAndUndefinedValuesFromObject({
        operation,
        listing_type: assetCardData.asset_features.listing_type,
        area: assetCardData.asset_features.area,
        address: address || addressByBypass || defaultLocation.address,
        lat: lat || coordinates.lat,
        lon: lon || coordinates.lon,
        ...(isTheSameAddress ? { ...cadastralInfo } : {}),
        ...(cadastralRefProperties && { ...cadastralRefProperties })
      });
      dispatch(
        setMapInfo({
          data: { ...map.data, coordinates: [Number(coordinates.lon), Number(coordinates.lat)] }
        })
      );
      dispatch(fetchAssetValuation({ data, isTheSameAddress }));
      dispatch(fetchComparables());
    }
  };

export const searchById = id => async (dispatch, getState) => {
  const snapshot = await dispatch(fetchSearchById(id));
  const { language } = getState().user.data;

  const { asset, search } = getState().smartValuation;
  const isTheSameAddress = search.address === asset?.address?.address;

  if (snapshot?.type?.includes('SUCCESS')) {
    dispatch(fetchFilters(FILTERS_CONFIG[language]));
    const assetCardData = snapshot.payload.data;
    const card = await dispatch(
      fetchAssetCard({ isTheSameAddress, ...assetCardData, features: assetCardData.asset_features })
    );

    const { listing_type } = snapshot.payload.data;
    dispatch(changeUse(listing_type));

    if (card.type.includes('SUCCESS')) {
      const { asset, operation } = getState().smartValuation;
      const data = getAssetValuationData({
        ...asset,
        ...assetCardData,
        lat: asset.lat,
        lon: asset.lon,
        operation
      });

      dispatch(fetchComparables());
      dispatch(fetchAssetValuation({ data }));
    }
  }
};

export const fetchComparables = () => dispatch => {
  dispatch(fetchComparablesMap());
  dispatch(fetchComparablesItems());
};

export const updateAverageComparable = comparableList => async dispatch => {
  dispatch(changeAverageComparable(getAverageValueOfComparable(comparableList)));
};

export const updateOperation = payload => (dispatch, getState) => {
  const { asset, search } = getState().smartValuation;
  const data = {
    //address: asset?.address?.address,
    address: search.address,
    lat: asset?.lat,
    lon: asset?.lon,
    reference: search.url_cadastralReference
  };
  dispatch(changeOperation(payload));
  dispatch(getData(data));
};

export const updateLayout = payload => async dispatch => {
  await dispatch(changeLayout(payload));
};

export const shareAssetValuation = () => (dispatch, getState) => {
  const { asset, operation } = getState().smartValuation;
  const data = getAssetValuationData({
    ...asset,
    address: asset.address.address,
    operation
  });

  return dispatch(fetchAssetValuation({ data, params: { save: true } }));
};

export const changeLayout = payload => ({
  type: 'UPDATE_LAYOUT',
  payload
});

export const changeOperation = payload => ({
  type: 'CHANGE_OPERATION',
  payload
});

export const changeAverageComparable = payload => ({
  type: 'CHANGE_AVERAGE',
  payload
});

export const changeUse = payload => ({
  type: 'CHANGE_USE',
  payload
});

export const fetchSearchById = id => ({
  type: 'FETCH_SEARCH_BY_ID',
  payload: {
    request: {
      method: 'GET',
      url: `/b4p/smart-valuation/search/${id}/`
    }
  }
});

export const resetComparableDetails = () => dispatch => {
  dispatch(setMapInfo({ comparableClicked: null }));
  dispatch({ type: 'RESET_COMPARABLE_DETAILS', payload: null });
};

export const fetchSearchSuggestions = ({ query, country_codes }) => ({
  type: 'FETCH_SEARCH_SUGGESTIONS',
  payload: {
    request: {
      method: 'GET',
      url: `/b4p/smart-valuation/autocomplete/`,
      params: { query, countries: country_codes.join(',') }
    }
  }
});

export const resetSuggestions = () => ({
  type: 'RESET_SEARCH_SUGGESTIONS',
  payload: null
});

const getDefaultAssetValuationFeatures = asset_features => {
  return {
    area: asset_features.area,
    address: asset_features.address,
    lat: asset_features.lat,
    lon: asset_features.lon,
    listing_type: asset_features.listing_type,
    property_type: asset_features.property_type ? asset_features.property_type : undefined,
    floor: typeof asset_features.floor === 'number' ? asset_features.floor : undefined,
    has_garage: asset_features.has_garage ? asset_features.has_garage : undefined,
    has_pool: asset_features.has_pool ? asset_features.has_pool : undefined,
    has_storage: asset_features.has_storage ? asset_features.has_storage : undefined,
    has_elevator: asset_features.has_elevator ? asset_features.has_elevator : undefined,
    has_terrace: asset_features.has_terrace ? asset_features.has_terrace : undefined,
    has_air_conditioner: asset_features.has_air_conditioner
      ? asset_features.has_air_conditioner
      : undefined,
    is_exterior: asset_features.is_exterior ? asset_features.is_exterior : undefined,
    build_status: asset_features.build_status ? asset_features.build_status : undefined
  };
};

export const fetchAssetValuation =
  ({ data, isTheSameAddress, params }) =>
  async dispatch => {
    const response = await dispatch({
      type: 'FETCH_ASSET_VALUATION',
      payload: {
        request: {
          method: 'POST',
          url: `/b4p/smart-valuation/valuation/`,
          data: isTheSameAddress ? data : { ...data },
          params
        }
      }
    });
    setNewUrlParams(getDefaultAssetValuationFeatures(data));
    if (response.type.includes('SUCCESS')) dispatch(selectAVMComparables());
  };

const removeProfileFilters = (filtersConfig, filtersSelected) => {
  if (!filtersSelected?.listing_type && filtersSelected) return filtersSelected;
  const profileFilters = filtersConfig
    .find(({ value }) => value === filtersSelected?.listing_type)
    .assetFilters.reduce((acc, { filters }) => {
      return [...acc, ...filters.map(({ internal_field }) => internal_field)].filter(
        field => field !== 'area'
      );
    }, []);

  return Object.entries(filtersSelected).reduce((acc, [key, value]) => {
    if (profileFilters.includes(key)) return acc;
    return { ...acc, [key]: value };
  }, {});
};

export const fetchComparablesItems = params => async (dispatch, getState) => {
  const { sortingGrid, map, filters, advanceForm, operation } = getState().smartValuation;
  let data = comparablesDataFormatted(getState().smartValuation);
  const { competitors_geom__contained_by = [], center = {} } = map || {};
  if (competitors_geom__contained_by && center) {
    data = {
      ...data,
      competitors_geom__contained_by,
      lon: center.lon,
      lat: center.lat
    };
  }
  if (!data.area || !data.lat || !data.lon || !data.listing_type) return null;
  const sorting = sortingGrid;
  const order = formatSortParams(sorting);

  let dataWithFeatures = {
    ...removeProfileFilters(filters, data),
    asset_features: advanceForm || {}
  };

  if (advanceForm.listing_type !== 1) {
    dataWithFeatures = {
      lat: dataWithFeatures.lat,
      lon: dataWithFeatures.lon,
      area: advanceForm.area,
      geog__dwithin: advanceForm.geog__dwithin[0],
      date_main_range: advanceForm.date_main_range,
      operation: operation,
      area__range: dataWithFeatures.area__range,
      listing_type: advanceForm.listing_type,
      asset_features: {
        area: advanceForm.area,
        listing_type: advanceForm.listing_type,
        geog__dwithin: advanceForm.geog__dwithin,
        date_main_range: advanceForm.date_main_range
      }
    };
  }

  const response = await dispatch({
    type: 'FETCH_COMPARABLES_ITEMS',
    payload: {
      shouldAppend: params?.shouldAppend,
      request: {
        method: 'POST',
        url: `/b4p/smart-valuation/comparables/`,
        data: dataWithFeatures,
        params: {
          layout: 'grid',
          ...(order !== 'undefined' ? { order } : {}),
          page: params?.page || 1,
          page_size: 50
        }
      }
    }
  });

  if (response.type.includes('SUCCESS')) {
    dispatch(selectAVMComparables());
    dispatch(
      updateAverageComparable(response.payload.data.comparables.slice(0, N_COMPARABLE_TO_AVERAGE))
    );
  }
};

export const fetchComparablesMap = params => async (dispatch, getState) => {
  const { sortingGrid, map, filters, advanceForm, operation } = getState().smartValuation;
  const order = formatSortParams(sortingGrid);
  const comparableData = comparablesDataFormatted(getState().smartValuation);
  if (
    !comparableData?.area ||
    !comparableData.lat ||
    !comparableData.lon ||
    !comparableData.listing_type
  )
    return null;

  const { bbox, center, competitors_geom__contained_by } = map;

  const data = removeEmptyAndNullAndUndefinedValuesFromObject({
    ...comparableData,
    ...(center && { lon: center.lon, lat: center.lat }),
    bbox,
    competitors_geom__contained_by
  });

  let dataWithFeatures = {
    ...removeProfileFilters(filters, data),
    asset_features: advanceForm || {}
  };

  if (advanceForm.listing_type !== 1) {
    dataWithFeatures = {
      lat: dataWithFeatures.lat,
      lon: dataWithFeatures.lon,
      area: advanceForm.area,
      geog__dwithin: advanceForm.geog__dwithin[0],
      date_main_range: advanceForm.date_main_range,
      operation: operation,
      area__range: dataWithFeatures.area__range,
      listing_type: advanceForm.listing_type,
      asset_features: {
        area: advanceForm.area,
        listing_type: advanceForm.listing_type,
        geog__dwithin: advanceForm.geog__dwithin,
        date_main_range: advanceForm.date_main_range
      }
    };
  }

  dispatch({
    type: 'FETCH_COMPARABLES_MAP',
    payload: {
      request: {
        method: 'POST',
        url: `/b4p/smart-valuation/comparables/map`,
        data: dataWithFeatures,
        params: {
          ...(order !== 'undefined' ? { order } : {}),
          page: params?.page,
          page_size: 50
        }
      }
    }
  });
};

export const resetMap = () => dispatch => {
  dispatch({
    type: 'RESET_COMPARABLES_MAP',
    payload: null
  });
};

export const updateComparableSelection = data => {
  return {
    type: 'UPDATE_COMPARABLE_SELECTION',
    payload: data
  };
};

export const fetchComparableDetails = id => ({
  type: 'FETCH_COMPARABLE_DETAILS',
  payload: {
    request: {
      method: 'GET',
      url: `/b4p/smart-valuation/comparables/${id}/`
    }
  }
});

export const fetchComparableDownload = params => async dispatch => {
  const response = await dispatch({
    type: 'FETCH_COMPARABLE_DOWNLOAD',
    payload: {
      request: {
        method: 'GET',
        url: `/b4p/smart-valuation/comparables/download`,
        params,
        responseType: 'blob'
      }
    }
  });

  if (response.type === 'FETCH_COMPARABLE_DOWNLOAD_SUCCESS') {
    FileSaver.saveAs(
      response.payload.data,
      `comparable_${params.lat}${params.lon}_smart-valuation.xlsx`
    );
  }
};

export const fetchAssetCard = (isTheSameAddress, data) => ({
  type: 'FETCH_ASSET_CARD',
  payload: {
    request: {
      method: 'POST',
      url: `/b4p/smart-valuation/asset-profile/`,
      data: isTheSameAddress ? data : { lat: data.lat, lon: data.lon, reference: data.reference }
    }
  }
});

export const fetchFilters = data => ({
  type: 'FETCH_FILTERS',
  payload: data
});

const getDefaultFilters = (newFilters, filtersConfig) => {
  const isModifyingOnlyListingType =
    newFilters && Object.keys(newFilters).join('') === 'listing_type';

  const filtersByTypology =
    newFilters?.listing_type &&
    filtersConfig.find(({ value }) => value === newFilters?.listing_type);

  return filtersByTypology && isModifyingOnlyListingType
    ? getDefaultValuesFilterByUse(filtersByTypology)
    : {};
};

export const changeFilterForm = newFilters => (dispatch, getState) => {
  const { filters } = getState().smartValuation;

  dispatch({
    type: 'CHANGE_FILTER_FORM',
    payload: { ...newFilters, ...getDefaultFilters(newFilters, filters) }
  });
};

export const changeFilterSource = value => ({
  type: 'CHANGE_FILTER_SOURCE',
  payload: { value }
});

const setSelectedStatus = (selectedStatus, value, hasToAddValue) => {
  const selected = !hasToAddValue
    ? selectedStatus.filter(item => item !== value)
    : [...new Set([...selectedStatus, value])];

  return selected;
};

export const changeFilterPublishingStatus =
  ({ field, value }) =>
  (dispatch, getState) => {
    const { publishingStatus, selectedStatus } = getState().smartValuation;
    const selectedValue = publishingStatus?.filters.find(
      filter => filter.internal_field === field
    ).value;

    dispatch({
      type: 'CHANGE_FILTER_PUBLISHING_STATUS',
      payload: { value: { [field]: value } }
    });
    if (!value || !selectedStatus.includes(selectedValue)) {
      dispatch({
        type: 'CHANGE_FILTER_SELECTED_STATUS',
        payload: { value: setSelectedStatus(selectedStatus, selectedValue, Boolean(value)) }
      });
    }
  };

export const changeFilterSelectedStatus = value => (dispatch, getState) => {
  const { publishingStatus } = getState().smartValuation;
  const priceStatus = publishingStatus?.price_status || 'original';

  dispatch({
    type: 'CHANGE_FILTER_PUBLISHING_STATUS',
    payload: {
      value: {
        price_status: value.includes(0) ? priceStatus : '',
        sold: value.includes(2) || false,
        published: value.includes(1) || false
      }
    }
  });

  dispatch({
    type: 'CHANGE_FILTER_SELECTED_STATUS',
    payload: { value }
  });
};

export const removePublisingStatus =
  (statusToRemove = []) =>
  (dispatch, getState) => {
    const { publishingStatus, selectedStatus } = getState().smartValuation;
    const { publishingStatus: initialPublishingStatus } = initialState;

    const getStatusFromValue = value =>
      publishingStatus?.filters.find(i => i.value === value).internal_field;

    const status = {
      fields: statusToRemove.reduce(
        (acc, value) => ({ ...acc, [value]: initialPublishingStatus[value] }),
        {}
      ),
      selected: Array.isArray(selectedStatus)
        ? selectedStatus.filter(
            status => !Boolean(statusToRemove.find(e => e === getStatusFromValue(status)))
          )
        : []
    };

    dispatch({
      type: 'CHANGE_FILTER_PUBLISHING_STATUS',
      payload: { value: { ...status.fields } }
    });

    dispatch({
      type: 'CHANGE_FILTER_SELECTED_STATUS',
      payload: { value: status.selected }
    });
  };

export const resetFilters = () => ({
  type: 'RESET_FILTERS',
  payload: null
});

export const resetFiltersModal = () => ({
  type: 'RESET_FILTERS_MODAL',
  payload: null
});

export const setMapInfo = value => ({
  type: 'SET_SMARTVALUATION_MAP_INFO',
  payload: value
});

export const getCenterFromGeom = geom => {
  if (isEmptyArray(geom?.features)) return null;

  const [lon, lat] = turfCentroid(geom.features[0].geometry)?.geometry?.coordinates;

  return { lon, lat };
};

export const onDrawEnd = geom => async (dispatch, getState) => {
  const { map } = getState().smartValuation;
  const center = getCenterFromGeom(geom);

  let competitors_geom__contained_by = null;
  let bbox = null;

  if (!map.competitors_geom__contained_by && !geom.features.length) {
    return dispatch(setMapInfo({ isUserDrawing: false }));
  }
  if (geom.features.length) {
    competitors_geom__contained_by = getBoundariesFromPolygon(geom);
    bbox = getBboxFromPolygon(geom);
  }

  await dispatch(
    setMapInfo({
      isUserDrawing: false,
      center,
      competitors_geom__contained_by,
      bbox
    })
  );

  dispatch(fetchComparables());
  dispatch(setLoadingFromOperation(false));
};

export const onComparableMapClick = popupInfo => (dispatch, getState) => {
  dispatch(resetComparableDetails());
  dispatch(setMapInfo({ comparableClicked: popupInfo?.id }));
  dispatch({
    type: 'SET_MAP_POPUP_INFO',
    payload: { data: popupInfo }
  });
  dispatch(fetchComparableDetails(popupInfo?.id));
};
export const onComparableMapHover = popupInfo => (dispatch, getState) => {
  dispatch({
    type: 'SET_MAP_POPUP_INFO',
    payload: { data: popupInfo }
  });
};

export const removePopupInfo = () => (dispatch, getState) => {
  const { popup } = getState().smartValuation;
  if (popup) {
    dispatch({
      type: 'REMOVE_POPUP_INFO',
      payload: null
    });
  }
};

export const updateComparablesSort =
  ({ key, value }) =>
  (dispatch, getState) => {
    const { sortingGrid } = getState().smartValuation;
    const sorting = sortingGrid;
    const payload =
      sorting.sort !== key || sorting.order !== value ? { sort: key, order: value } : {};
    dispatch({
      type: 'UPDATE_SORTING',
      payload
    });
    dispatch(fetchComparablesItems());
    dispatch(fetchComparablesMap());
  };

export const updateComparablesPage = page => dispatch => {
  dispatch(fetchComparablesItems({ page, shouldAppend: true }));
  dispatch(fetchComparablesMap({ page }));
};

export const fetchIndicators = kpiId => (dispatch, getState) => {
  const { boundary_id, operation, asset } = getState().smartValuation;
  const headers = new Headers();

  const params = {
    code: kpiId,
    boundary_id: boundary_id,
    operation_id: operation,
    country_id: asset.address.country_id
  };

  const url = process.env.REACT_APP_API_BASE_URL + `/api/indicators/v1/cards`;

  dispatch({
    type: 'FETCH_INDICATORS',
    payload: {
      request: {
        method: 'GET',
        url: url,
        params: params
      }
    }
  });

  return;
};

export const onCreateReportTask =
  ({ reportId, selectedComparables }) =>
  async (dispatch, getState) => {
    const { publishingStatus, operation, comparables } = getState().smartValuation;
    const isPersonalizedReport = reportId === 2;
    const isNoComparablesReport = reportId === 3;
    const selectedComparablesLength = selectedComparables[operation].length;
    const comparablesLength = comparables?.items?.length;
    const operationValue = operation === OPERATION.rent ? 'rent' : 'sale';

    if (isPersonalizedReport && !comparablesLength) {
      dispatch(
        openNotification('warning', {
          message: `smartvaluation.report_modal_typology.without_comparables`
        })
      );
      return;
    }

    if ((isPersonalizedReport || isNoComparablesReport) && selectedComparablesLength < 1) {
      dispatch(
        openNotification('info', {
          message: `smartvaluation.report_modal_typology.error_${operationValue}`
        })
      );
      return;
    }

    if (isPersonalizedReport && selectedComparablesLength > 12) {
      dispatch(
        openNotification('info', {
          message: 'smartvaluation.asset.status_report_limit'
        })
      );
    }

    const response = await dispatch(
      createReportTask({
        reportId,
        selectedComparables,
        priceStatus: publishingStatus.price_status
      })
    );

    if (response) {
      if (!response?.type?.includes('SUCCESS')) {
        dispatch(
          openNotification('error', {
            message: 'smartvaluation.asset.status_report_error'
          })
        );
      }
    }
  };

export const transformUdaIdsToIds = selectedComparables => {
  let comparables = [];
  selectedComparables &&
    selectedComparables.forEach(({ id }) => (comparables = [...comparables, id]));
  return comparables;
};

const getPrice = (typeOfOperation, callback) => ({
  [`overwrite_${typeOfOperation}_price`]: {
    sqm: callback(typeOfOperation, 'sqm_value'),
    price: callback(typeOfOperation, 'price')
  }
});

const getOverWritePrices = (priceTypes = [], comparablesByOperation, area) => {
  return priceTypes.reduce(
    (acc, value) => ({
      ...acc,
      ...(comparablesByOperation[value].length &&
        getPrice(value, (key, priceType) => {
          const sqmPrice = Math.round(averageCalc(comparablesByOperation[key], 'sqm_value'));
          return priceType === 'sqm_value' ? sqmPrice : area * sqmPrice;
        }))
    }),
    {}
  );
};

export const createReportTask =
  ({ reportId, selectedComparables, priceStatus }) =>
  (dispatch, getState) => {
    const { asset, operation, advanceForm, sortingGrid, averageComparables, valuation } =
      getState().smartValuation;

    const { defaultLocation } = getState().user;
    const smartValuation = getState().smartValuation;
    const newDefaultLocation = smartValuation.search?.address?.split(',');

    const form = getAssetValuationFeatures(advanceForm);
    const comparableData = comparablesDataFormatted(getState().smartValuation);
    const orderBy = formatSortParams(sortingGrid);
    const isUDAReport = reportId === 1;
    const operationName = operation === OPERATION.rent ? 'rent' : 'sale';
    const valuationOverWritePrice = isUDAReport
      ? getPrice(operationName, (_, priceType) =>
          priceType === 'price'
            ? valuation?.uda?.primary?.value || averageComparables?.uda?.primary?.value
            : valuation?.uda?.secondary?.value || averageComparables?.uda?.secondary?.value
        )
      : {};

    const analyst_comparables = transformUdaIdsToIds(
      Object.values(selectedComparables)
        .reduce((acc, current) => [...acc, ...current], [])
        .slice(0, 12)
    );

    const selectedComparablesByOperation = {
      rent: applyPriceModificationToSelected(selectedComparables['0'], priceStatus),
      sale: applyPriceModificationToSelected(selectedComparables['1'], priceStatus)
    };

    const data = {
      ...(isUDAReport
        ? valuationOverWritePrice
        : getOverWritePrices(['rent', 'sale'], selectedComparablesByOperation, advanceForm.area)),
      address: asset?.address?.address || newDefaultLocation[0],
      area: advanceForm.area,
      asset_features: form,
      comparables_filters: comparableData,
      comparables_order: orderBy,
      analyst_comparables,
      country: 'es',
      lat: asset.lat,
      lon: asset.lon,
      listing_type: advanceForm.listing_type,
      operation,
      prediction_mode: 'best',
      report_type: reportId
    };

    return dispatch({
      type: 'FETCH_REPORT_TASK',
      payload: {
        request: {
          method: 'POST',
          url: `/b4p/smart-valuation/reports/pdf-pulse/`,
          data
        }
      }
    }).then(response => {
      const idTask = response.payload.data.task_id;

      const fetchReportTask = () => {
        return dispatch({
          type: 'FETCH_REPORT_BY_TASK',
          payload: {
            request: {
              method: 'GET',
              url: `/b4p/task_async/task/${idTask}/`,
              data
            }
          }
        }).then(response => {
          if (response.payload.data.status === 'SUCCESS') {
            dispatch(
              updateReportTaskInfo({
                status: 'SUCCESS',
                url: response.payload.data.result.url,
                isLoading: false
              })
            );
          } else if (
            response.payload.data.status === TASK_STATUS_FAILURE ||
            response.payload.data.status === TASK_STATUS_REVOKED
          ) {
            dispatch(
              openNotification('error', {
                message: 'smartvaluation.asset.status_report_error'
              })
            );
            dispatch(
              updateReportTaskInfo({ status: null, url: null, task_id: null, isLoading: false })
            );
          } else {
            return new Promise(resolve => {
              setTimeout(() => {
                resolve(fetchReportTask());
              }, 2000);
            });
          }
        });
      };

      return fetchReportTask();
    });
  };

export const updateReportTaskInfo = data => ({
  type: 'UPDATE_REPORT_TASK_INFO',
  payload: data
});

export const fetchReportTaskStatus = (intl, task_id) => async (dispatch, getState) => {
  const action = {
    type: 'FETCH_REPORT_TASK_STATUS',
    payload: {
      request: {
        method: 'GET',
        url: `/b4p/task_async/task/${task_id}/`
      }
    }
  };

  const interval = setInterval(async () => {
    const response = await dispatch(action);

    if (response.type.includes('SUCCESS')) {
      if (response.payload.data.status === TASK_STATUS_SUCCESS) {
        dispatch(
          updateReportTaskInfo({
            status: 'SUCCESS',
            url: response.payload.data.result.url,
            isLoading: false
          })
        );
        dispatch(
          openNotification('success', {
            message: intl.formatMessage({
              id: 'smartvaluation.asset.status_report_ready'
            })
          })
        );

        clearInterval(interval);
      }
      if (
        response.payload.data.status === TASK_STATUS_FAILURE ||
        response.payload.data.status === TASK_STATUS_REVOKED
      ) {
        dispatch(
          openNotification('error', {
            message: 'smartvaluation.asset.status_report_error'
          })
        );
        dispatch(
          updateReportTaskInfo({ status: null, url: null, task_id: null, isLoading: false })
        );
        clearInterval(interval);
      }
    }
  }, 3000);
};

export const downloadReport = () => (dispatch, getState) => {
  const { report } = getState().smartValuation;
  if (report?.url) window.open(report.url, '_blank');
  dispatch(closeNotification());
  dispatch(updateReportTaskInfo({ status: null, url: null, task_id: null, isLoading: false }));
};

export const selectAVMComparables = () => (dispatch, getState) => {
  const { comparables, valuation } = getState().smartValuation;

  if (valuation && valuation.info) {
    if (comparables?.items?.length > 0) {
      dispatch({
        type: 'UPDATE_COMPARABLES_AVM_STATUS',
        payload: comparables.items.map(c => {
          if (valuation.info.selected_comparables.includes(c.id_uda)) {
            return {
              ...c
            };
          }
          return c;
        })
      });
    }
  }
};

export const fetchGeoReverseAddress = (lat, lon, country_codes) => ({
  type: 'FETCH_REPORT_TASK_STATUS',
  payload: {
    request: {
      method: 'GET',
      url: `/b4p/smart-valuation/geocoding/reverse`,
      params: { lat, lon, countries: country_codes.join(',') }
    }
  }
});

const fetchCheckoutSession = data => ({
  type: 'FETCH_STRIPE_CHECKOUT_SESSION',
  payload: {
    request: {
      method: 'POST',
      url: '/payments/payments/create_checkout_session/',
      data
    }
  }
});

export const reportCheckout = stripe => async (dispatch, getState) => {
  const baseUrl = window.location.origin;
  const { asset, advanceForm, sortingGrid, operation } = getState().smartValuation;
  const form = getAssetValuationFeatures(advanceForm);
  const comparableData = comparablesDataFormatted(getState().smartValuation);
  const orderBy = formatSortParams(sortingGrid);

  const data = {
    success_url: `${baseUrl}/checkout/asset/report/summary`,
    cancel_url: `${baseUrl}/asset/`,
    paid_product: 'smart-valuation-reports',
    payload: {
      asset_features: form,
      comparables_filters: comparableData,
      comparables_order: orderBy,
      listing_type: advanceForm.listing_type,
      operation,
      lat: asset.lat,
      lon: asset.lon,
      area: asset.area,
      address: asset.address.address,
      prediction_mode: 'ML1'
    }
  };
  const action = await dispatch(fetchCheckoutSession(data));

  if (action.type === 'FETCH_STRIPE_CHECKOUT_SESSION_SUCCESS') {
    const { session_id } = action.payload.data;
    stripe.redirectToCheckout({ sessionId: session_id });
  }
};

export const comparablesDataFormatted = smartValuation => {
  const { asset, operation, advanceForm, sources, selectedStatus, filters } = smartValuation;
  const filterByTypology = filters.find(({ value }) => value === advanceForm.listing_type);
  const defaultAreaRange =
    advanceForm.listing_type === 1
      ? filterByTypology?.comparablesFilters[2]?.filters[0]?.values
      : filterByTypology?.comparablesFilters[1]?.filters[0]?.values;

  const form = getAssetValuationFeatures(advanceForm);

  const comparableData = getComparablesData({
    ...asset,
    ...form,
    operation,
    ...(!form?.area__range && { area__range: defaultAreaRange }),
    source_id__in: sources.selected,
    publishing_status__in: selectedStatus
  });

  return parseComparablesFilters(removeEmptyAndNullAndUndefinedValuesFromObject(comparableData));
};

export const getReportsStyles = () => ({
  type: 'GET_REPORTS_STYLES',
  payload: {
    request: {
      method: 'GET',
      url: '/b4p/smart-valuation/report-style/'
    }
  }
});

export const getStyle = id => ({
  type: 'GET_REPORT_STYLE',
  payload: {
    request: {
      method: 'GET',
      url: `/b4p/smart-valuation/report-style/${id}/`
    }
  }
});

export const deleteReportStyle = id => ({
  type: 'DELETE_REPORT_STYLE',
  payload: {
    request: {
      method: 'DELETE',
      url: `/b4p/smart-valuation/report-style/${id}/`
    }
  }
});

export const onUpdateReportStyle = (id, data) => ({
  type: 'UPDATE_REPORT_STYLE',
  payload: {
    request: {
      method: 'PATCH',
      url: `/b4p/smart-valuation/report-style/${id}/`,
      data
    }
  }
});

export const updateReportStyle = (id, data) => async dispatch => {
  const response = await dispatch(onUpdateReportStyle(id, data));
  if (response.type === 'UPDATE_REPORT_STYLE_SUCCESS') window.location.reload();
};

const onCreateReportStyle = data => ({
  type: 'CREATE_REPORT_STYLE',
  payload: {
    request: {
      method: 'POST',
      url: `/b4p/smart-valuation/report-style/`,
      data
    }
  }
});

export const createReportStyle = data => async dispatch => {
  const response = await dispatch(onCreateReportStyle(data));
  if (response.type === 'CREATE_REPORT_STYLE_SUCCESS') window.location.reload();
};

export const uploadCompanyLogo = file => {
  let formData = new FormData();
  formData.append('file', file);

  return {
    type: 'UPDATE_COMPANY_LOGO',
    payload: {
      request: {
        method: 'POST',
        url: `${process.env.REACT_APP_API_PROXY_BASE_URL}/b4p/smart-valuation/report-style/upload/`,
        data: formData
      }
    }
  };
};

export const uploadContactImage = file => {
  let formData = new FormData();
  formData.append('file', file);

  return {
    type: 'UPDATE_CONTACT_IMAGE',
    payload: {
      request: {
        method: 'POST',
        url: `${process.env.REACT_APP_API_PROXY_BASE_URL}/b4p/smart-valuation/report-style/upload/`,
        data: formData
      }
    }
  };
};

export const selectReportStyle = id => ({
  type: 'SELECT_REPORT_STYLE',
  payload: {
    request: {
      method: 'PATCH',
      url: `/b4p/smart-valuation/report-style/${id}/`,
      data: {
        is_selected: true
      }
    }
  }
});

export const startNewReportStyle = () => ({
  type: 'RESET_REPORT_STYLE',
  payload: null
});

export const setSearchedBefore = isSearchedBefore => ({
  type: 'SET_SEARCHED_BEFORE',
  payload: isSearchedBefore
});

export * from 'SmartValuation/features/Search/context';
